import React, { Component } from 'react';
import { AddUserPage } from '../pages';

class UsersContainer extends Component {
  render() {
    return <AddUserPage />;
  }
}

export default UsersContainer;
