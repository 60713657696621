import React, { Component } from 'react';
import { CommpunityPage } from '../pages';

class CommunityContainer extends Component {
  render() {
    return <CommpunityPage />;
  }
}

export default CommunityContainer;
